import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const OnamaPage = () => (
  <Layout>
    <div id="wrapper">
      <ul>
        <li><Link to="/" className="button ">Nazad</Link></li>
      </ul>
        <article
            id="about"
            className="about"
        >
            <h2 className="major">O nama</h2>
            <p>
            Pre tačno 10 godina, ekipa entuzijasta se okupljala na FON-u, oko
            studentske ogranizacije FONIS, sa idejom da studentima IT smera
            pruži dodatnu edukaciju iz sveta programiranja. Nakon par godina
            FONIS prerasta u veliku IT organizaciju.
            </p>
            <p>
            Ta ista ekipa entuzijasta danas vodi ozbiljne IT kompanije i IT
            projekte. Pored tehnologije, sport je nit koja nas dodatno sve
            povezuje, a pre svega basket. Sećam se davno održnog Biz Buzz
            turnira u basketu. Tada se, iako mala, IT zajednica okupila oko
            jednog sjajnog projekta, gde smo svi pored sjajnih učenja, imali i
            deo rekreacije uz turnir u basketu.{' '}
            </p>
            <p>
            Kako danas svi negde žure, nemaju vremena da spuste tempo i zastanu
            na trenutak da uživaju i odmore, došli smo na ideju da to pokušamo
            da rešimo. A kada se već bavimo rekreacijom i druženjem, zašto uz
            sve to da i ne pomognemo, onima kojima je pomoć najpotrebnija, a to
            su naša deca.{' '}
            </p>
            <p>
            Pozivam sve IT stručnjake i IT entuzijaste da izađemo na teren,
            ostavimo na trenutak računare po strani, igramo basket i pritom
            budemo humani.{' '}
            </p>
            <p>- Nemanja Čedomirović ispred organizacionog tima turnira.</p>
            <p>
            Organizatori turnira -{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.growit.tech/">
                GrowIT
            </a>
            ,{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://phpsrbija.rs/">
                PHP Srbija
            </a>{' '}
            i{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://ardeo.solutions/">
                Ardeo Solutions.
            </a>
            </p>
        </article>
    </div>
  </Layout>
)

export default OnamaPage
